<template>
    <div class="">
        <div class="inline-flex items-center justify-center w-full">
            <div class="bg-white p-5 rounded-lg border-2 border-b-4 border-gray-800">
                <p class="text-3xl font-bold mb-3">Psst...</p>
                <p class="text-2xl font-semi-bold">Hey buddy, you lookin' for some clones?!?</p>
            </div>
            <div class="text-center ml-2">
                <img src="../assets/mascot.png" class="hero-image w-50 h-50"/>
            <p class="text-xs italic">The git_clones mascot, Dr. A. P. Istein</p>
            </div>
        </div>
        <div class="w-full prose my-10 bg-green-200 p-5 border-2 border-b-4 border-gray-800 rounded-lg">
            <p class="text-lg font-semibold text-center my-1">You've just found the most complete and curated listing of GitHub repositories that copy or clone existing products.</p>
            <p>We've sifted through the noise to bring you only the best of the best—no filler, just killer repos. Whether you're a developer hunting for gold, a student sharpening your skills, or a tech enthusiast diving deep into your favorite apps, we've got you covered.</p>
        </div>
    </div>
</template>

<style scoped>

.hero-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

</style>