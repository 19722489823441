<template>
    <div class="container py-36 mx-auto w-full">
      <h2 class="text-xl font-bold py-3">Submit a New Clone Repository</h2>
      <div class="rounded-lg bg-white p-5 border-2 border-b-4 border-gray-800">
        <form 
            name="suggest-repo" 
            method="POST" 
            class="w-full" 
            data-netlify="true" 
            data-netlify-honeypot="bot-field"
            action="/views/success"
            >
            <input type="hidden" name="form-name" value="suggest-repo" />
            <div class="form-group mb-3">
            <label for="name">Repository Name</label>
            <input
                type="text"
                id="name"
                name="name"
                v-model="form.name"
                class="form-control bg-white border-2 rounded-lg border-gray-800 border-b-4 h-12 w-full flex items-center p-4"
                required
            />
            </div>
            <div class="form-group mb-3">
            <label for="link">Repository Link</label>
            <input
                type="url"
                id="link"
                name="link"
                v-model="form.link"
                class="form-control bg-white border-2 rounded-lg border-gray-800 border-b-4 h-12 w-full flex items-center p-4"
                required
            />
            </div>
            <div class="form-group mb-3">
            <label for="category">Category</label>
            <select id="category" v-model="form.category" name="category" class="form-select block appearance-none w-full border-3 border-gray-800 rounded-lg leading-tight px-3 mb-4 border-2 rounded-lg border-gray-800 border-b-4 h-12 text-gray-800" required>
                <option value="" selected>Select a Category</option>
                <option value="Social Media">Social Media</option>
                <option value="Accommodation">Accommodation</option>
                <option value="Music Streaming">Music Streaming</option>
                <option value="Video Streaming">Video Streaming</option>
                <option value="Professional Networking">Professional Networking</option>
                <!-- Add more categories as needed -->
            </select>
            </div>
            <div class="form-group mb-3">
            <label for="cloneOf">Clone Of</label>
            <input
                type="text"
                id="cloneOf"
                name="cloneOf"
                v-model="form.cloneOf"
                class="form-control bg-white border-2 rounded-lg border-gray-800 border-b-4 h-12 w-full flex items-center p-4"
                required
            />
            </div>
            <input type="text" class="hidden" name="bot-field" />
            <button type="submit" name="submit" class="text-xl font-medium px-3 h-12 border-gray-800 m-1 border-2 rounded-lg border-b-4 flex items-center active:border-b-2 highlight focus:outline-none hover:bg-green-600 focus:bg-green-600 bg-green-400 text-white">Submit</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SubmitCloneForm',
    data() {
      return {
        form: {
          name: '',
          link: '',
          category: '',
          cloneOf: '',
        }
      };
    },
    // methods: {
    //   handleSubmit() {
    //     // Process form data and submit to backend or store in database
    //     // Here, we just log the form data to the console for demonstration
    //     const newRepo = {
    //       ...this.form,
    //       technologies: this.form.technologies.split(',').map(tech => tech.trim())
    //     };
    //     console.log(newRepo);
  
    //     // Clear the form
    //     this.form = {
    //       name: '',
    //       link: '',
    //       technologies: '',
    //       category: '',
    //       cloneOf: '',
    //       description: ''
    //     };
    //   }
    // }
  };

  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  
