<template>
    <div>
        <nav class="fixed top-0 w-full border-b border-gray-300 h-20 bg-white text-gray-800 flex items-center px-4 z-50">
            <div class="container mx-auto">
                <div class="inline-flex w-full items-center justify-between">
                    <div class="items-center">
                        <a href="/" class="flex items-center" aria-current="page">
                            <button class="h-12 rounded-lg px-3 border-2 border-b-4 border-gray-800 focus:outline-none active:border-b-2 mr-2 focus:bg-gray-300 inline-flex items-center">
                                <img src="../assets/mascot.png" class="h-10 w-auto"/>
                                <p class="ml-2 text-2xl leading-none font-semibold hidden lg:block md:block xl:block">git_clones</p> 
                            </button>
                        </a>
                    </div>
                    <!-- <div class="">
                        <RouterLink to="/suggest" class="text-xl font-medium px-3 h-12 border-gray-800 m-1 border-2 rounded-lg border-b-4 flex items-center active:border-b-2 highlight focus:outline-none hover:bg-green-600 focus:bg-green-600 bg-green-400 text-white" title="Suggest a repo">
                            Suggest a Repo
                        </RouterLink>
                    </div> -->
                </div>
            </div>
        </nav>
    </div>
</template>