// src/utils/technologyLogos.js
const technologyLogos = {
    React: require('@/assets/logos/react.png'),
    'React Native': require('@/assets/logos/react.png'),
    Vue: require('@/assets/logos/vue.png'),
    'Node.js': require('@/assets/logos/node.png'),
    'Next.js': require('@/assets/logos/nextjs.png'),
    Firebase: require('@/assets/logos/firebase.png'),
    MongoDB: require('@/assets/logos/mongo.png'),
    Express: require('@/assets/logos/express.png'),
    Redux: require('@/assets/logos/redux.png'),
    Laravel: require('@/assets/logos/laravel.png'),
    TypeScript: require('@/assets/logos/typescript.png'),
    MySQL: require('@/assets/logos/mysql.png'),
    GraphQL: require('@/assets/logos/graphql.png'),
    PostgreSQL: require('@/assets/logos/postgresql.png'),
    TailwindCSS: require('@/assets/logos/tailwind.png'),
    Angular: require('@/assets/logos/angular.png'),
    'Socket.io': require('@/assets/logos/socketio.png'),
    Dart: require('@/assets/logos/dart.png'),
    Flutter: require('@/assets/logos/flutter.png'),
    Mongoose: require('@/assets/logos/mongoose.png'),
    JavaScript: require('@/assets/logos/javascript.png'),
    HTML: require('@/assets/logos/html.png'),
    CSS: require('@/assets/logos/css.png'),
    Django: require('@/assets/logos/django.png'),
    Python: require('@/assets/logos/python.png'),
    OpenGL: require('@/assets/logos/opengl.png'),
    C: require('@/assets/logos/c.png'),
    Kotlin: require('@/assets/logos/kotlin.png'),
    Nuxt: require('@/assets/logos/nuxt.png'),
    'Material UI': require('@/assets/logos/materialui.png'),
    // Add more mappings as needed
  };
  
  export default technologyLogos;
