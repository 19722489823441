<template>
    <footer class="bg-black text-white py-8 mt-12">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-between">
          <div class="w-full md:w-1/3 mb-6 md:mb-0 px-2">
            <h3 class="text-xl font-semibold">About git_clones</h3>
            <p class="mt-4 text-gray-400">
              git_clones is your go-to resource for discovering and exploring the best GitHub repositories that clone real-life applications. Learn from these repositories to build your own versions or gain insights into popular technologies.
            </p>
          </div>
          <div class="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 class="text-xl font-semibold">Quick Links</h3>
            <ul class="mt-4 text-gray-400">
              <li class="mb-2">
                <router-link to="/what-makes-a-good-clone" class="hover:text-white">What makes a good clone?</router-link>
              </li>
              <!-- <li class="mb-2">
                <router-link to="/submit" class="hover:text-white">Submit a Clone</router-link>
              </li> -->
              <li class="mb-2">
                <router-link to="/about" class="hover:text-white">About git_clones</router-link>
              </li>
              <!-- <li class="mb-2">
                <router-link to="/contact" class="hover:text-white">Contact</router-link>
              </li> -->
            </ul>
          </div>
          <div class="w-full md:w-1/3">
            <h3 class="text-xl font-semibold">Stay Connected</h3>
            <p class="mt-4 text-gray-400">
              Follow us on social media and stay updated with the latest clone repositories.
            </p>
            <div class="flex mt-4">
              <!-- <a href="#" class="text-gray-400 hover:text-white mr-6">
                <font-awesome-icon :icon="['fab', 'facebook-f']" />
              </a> -->
              <a href="https://x.com/gitclones" class="text-gray-400 hover:text-white mr-6">
                <font-awesome-icon :icon="['fab', 'x-twitter']" />
              </a>
              <!-- <a href="#" class="text-gray-400 hover:text-white mr-6">
                <font-awesome-icon :icon="['fab', 'github']" />
              </a>
              <a href="#" class="text-gray-400 hover:text-white">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
              </a> -->
            </div>
          </div>
        </div>
        <div class="mt-8 text-center text-gray-400">
          &copy; 2024 gitclones.com. All rights reserved.
        </div>
      </div>
    </footer>
  </template>
  
  <script>


  export default {
    name: 'MainFooter'
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 1200px;
  }
  </style>
  