import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SuggestView from '../views/SuggestView.vue'
import SuccessView from '../views/SuccessView.vue'
import WhatMakesAGoodClone from '../views/WhatMakesAGoodClone.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/suggest',
    name: 'suggest',
    component: SuggestView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/what-makes-a-good-clone',
    name: 'good-clone',
    component: WhatMakesAGoodClone
  },
  {
    path: '/:notFound',
    name: 'not-found',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
