<template>
    <div class="container mx-auto pt-36 mb-10 h-dvh">
        <div class="flex flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center w-full">
            <div class="bg-white p-5 rounded-lg border-2 border-b-4 border-gray-800">
                <p class="text-3xl font-bold mb-3">Hey buddy...</p>
                <p class="text-2xl font-semi-bold mb-3">You look lost. Have I told you about our lord and savior, <a href="https://en.wikipedia.org/wiki/Cthulhu" class="text-green-400 hover:text-green-700">Cthulhu?</a> </p>
                <p class="text-2xl font-semi-bold">Oh, I see. You just want to go back to civilization. <router-link to="/" class="text-green-400 hover:text-green-700"> Here's a link to take you home.</router-link> </p>
            </div>
            <div class="text-center ml-2">
                <img src="../assets/mascot.png" class="hero-image w-50 h-50"/>
            </div>
        </div>
    </div>

</template>

<style scoped >

.hero-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

</style>