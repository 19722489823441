<template>
  <div class="bg-slate-100 relative">
    <TopNav/>
    <div class="mx-3">
      <router-view/>
    </div>
    <MainFooter/>
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue'
import MainFooter from './components/MainFooter.vue'

export default {
  name: 'AppView',
  components: {
    MainFooter, TopNav
  }
}

</script>

<style lang="scss">

</style>
