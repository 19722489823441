<template>
    <div class="container py-48 text-center mx-auto h-screen">
      <div class="bg-white rounded-lg border-2 border-b-4 border-gray-800 p-5">
        <h2>Successfully Submitted!</h2>
        <p>Your repository submission has been received.</p>
        <p>You will be redirected to the home page in 5 seconds...</p>
        <p>If you are not redirected, <router-link to="/" class="text-green-400">click here</router-link>.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConfirmationPage',
    mounted() {
      setTimeout(() => {
        this.$router.push('/');
      }, 15000);
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  