<template>
  <div class="my-3">
    <div class="container">
      <div class="">
        <div class="d-inline-flex my-3">
          <h3 class="font-bold text-xl" title="(not actually&#8482;)">Clone Discovery Tools&#8482;</h3>
        </div>
        <div class="flex items-center w-full">
          <div class="bg-white border-2 rounded-lg border-gray-800 border-b-4 h-12 w-full flex items-center">
            <span class="ml-4">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </span>
            <form class="w-full">
              <input v-model="searchQuery" type="text" placeholder="Search by name, cloned app, technology..." class="focus:outline-none w-full px-4">
            </form>
          </div>
        </div>
        <div class="my-2">
          <button @click="updateCategory('')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">All</button>
          <button @click="updateCategory('Community')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Community</button>
          <button @click="updateCategory('Content Creation')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Content Creation</button>
          <button @click="updateCategory('Gaming')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Gaming</button>
          <button @click="updateCategory('Marketplace')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Marketplace</button>
          <button @click="updateCategory('Productivity')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Productivity</button>
          <button @click="updateCategory('Project Management')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Project Management</button>
          <button @click="updateCategory('Social Media')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Social Media</button>
          <button @click="updateCategory('Streaming')" class="bg-white border-2 rounded-lg border-gray-800 border-b-4 text-lg py-1 px-2 m-2">Streaming</button>
        </div>
      </div>
      <hr />
      <RepoCard
        v-for="repo in filteredAndSortedRepos"
        :key="repo.id"
        :repo="repo"
      />
    </div>
  </div>
</template>

<script>
import RepoCard from './RepoCard';
import repos from '../assets/repodata.json';

export default {
  name: 'MainList',
  components: {
    RepoCard,
  },
  data() {
    return {
      repos: repos.repos,
      searchQuery: '',
      selectedCategory: '',
      selectedCTechnologies: '',
      sortKey: 'name',
    };
  },
  methods: {
    updateCategory(category) {
      this.selectedCategory = category;
    },
  },
  computed: {
    filteredAndSortedRepos() {
      let result = this.repos;

      // Filter by category
      if (this.selectedCategory) {
        result = result.filter((repo) => repo.category === this.selectedCategory);
      }

      // Filter by technology
      if (this.selectedCTechnologies) {
        result = result.filter((repo) => repo.technologies.includes(this.selectedCTechnologies));
      }

      // Search
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        result = result.filter((repo) => {
          return (
            repo.name.toLowerCase().includes(query) ||
            repo.category.toLowerCase().includes(query) ||
            repo.cloneOf.toLowerCase().includes(query) ||
            repo.technologies.some((tech) => tech.toLowerCase().includes(query))
          );
        });
      }

      // Sort by key
      result = result.sort((a, b) => {
        if (a[this.sortKey] < b[this.sortKey]) return -1;
        if (a[this.sortKey] > b[this.sortKey]) return 1;
        return 0;
      });

      return result;
    },
  },
};
</script>

<style scoped>
/* Add any styles here */
</style>
