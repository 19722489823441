<template>
  <div class="home">
    <section class="container mx-auto min-h-screen home-section py-36">
      <MainBlurb/>
      <MainList/>
    </section>
  </div>
</template>

<script>
import MainList from '@/components/MainList.vue'
import MainBlurb from '@/components/MainBlurb.vue';

export default {
  name: 'HomeView',
  components: {
    MainList, MainBlurb
  }
}
</script>

<style scoped>
  .home-section {
    height: auto !important;
    min-height: 0px !important;
  }
</style>
