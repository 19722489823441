<template>
    <div class="my-4 rounded-lg bg-white text-gray-800 border-2 border-b-4 border-gray-800 p-5">
      <div class="">
        <h4 class="font-bold text-3xl">{{ repo.name }}</h4>
        <div class="">
          <p class="my-2">{{ repo.description }}</p>
          <p><span class="font-bold">Category:</span> {{ repo.category }}</p>
          <p><span class="font-bold">Clone of:</span> {{ repo.cloneOf }}</p>
        </div>
        <div class=" tech-list">   
            <p class="font-bold">Technologies:</p>       
            <span v-for="tech in repo.technologies" :key="tech" class="m-2 py-1 px-2 inline-flex h-12 rounded-lg bg-white text-gray-800 border-2 border-b-4 border-gray-800 items-center">
                <img :src="getTechnologyLogo(tech)" :alt="tech" class="logo-img" />
                <p class="fw-bold my-auto">{{ tech }}</p>
            </span>
        </div>
        <hr/>
        <ul class="inline-flex justify-end w-full my-2">
          <li v-for="(link) in repo.links" :key="link.key" class=" mx-1">
            <a :href="link" target="_blank" class="">
              <button class=" font-bold text-xl py-1 px-5 h-10 text-green-400 hover:text-green-700 border-2 border-green-400 rounded-lg">
                View on GitHub
              </button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import technologyLogos from '@/utils/technologyLogos';
  
  export default {
    name: 'RepoCard',
    props: {
      repo: {
        type: Object,
        required: true
      }
    },
    methods: {
      getTechnologyLogo(tech) {
        return technologyLogos[tech] || '';
      }
    }
  };
  </script>
  
  <style scoped>
  .tech-logo {

    margin-right: 10px;
    vertical-align: middle;
  }
  .logo-img {
    width: auto;
    height: 20px;
    margin-right: 5px;
  }
  </style>
  
  